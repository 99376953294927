<template>
  <div class="home-page-wrapper">
    <Header />
  
    <!--  mobile menu-->
    <MobileMenu />

    <b-overlay :show="loading">
      <section id="services" class="services-section my-0">
        <div class="story-container mt-md-5">

          <div>
            <div class="d-md-flex align-items-center mb-2 featured-image">
              <b-img :src="getValueFromSource(story, 'cover_image.path', '')" fluid alt="Cover image"></b-img>
            </div>
            <h1 class="font-weight-bolder story-title">
              {{ story.title }}
            </h1>
            <div class="text-muted mb-3">
              Published: {{ getPublishedDate() }}
            </div>
          </div>

          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="story-content" v-html="getValueFromSource(story, 'content')"></div>

          <div class="position-absolute design" style="top: 70%; left: 0;">
            <div class="d-none pc">
              <svg width="290" height="414" viewBox="0 0 290 414" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.2">
                  <path
                    d="M-59.2238 1.52588e-05H-148.731L205.384 357.05L290 353.137L150.604 213.251L290 69.4535H205.384L104.628 167.275L82.1287 146.244L222.992 1.52588e-05H135.931L38.1089 97.3327L-59.2238 1.52588e-05Z"
                    fill="#ADCD39"
                  />
                  <path
                    d="M-118.406 69.4535H-204L-65.5822 209.339L-204 346.289H-118.406L-21.5624 254.337L0.93663 273.901L-136.992 413.786H-51.398L90.9327 273.901L-118.406 69.4535Z"
                    fill="#459042"
                  />
                </g>
              </svg>
            </div>
            <div class="d-none mobile">
              <svg width="144" height="217" viewBox="0 0 144 217" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.2">
                  <path
                    d="M-39.1505 7.62939e-06H-86.0439L99.4799 187.061L143.811 185.011L70.78 111.724L143.811 36.3873H99.4799L46.6927 87.637L34.9053 76.6183L108.705 7.62939e-06H63.0926L11.8429 50.9934L-39.1505 7.62939e-06Z"
                    fill="#ADCD39"
                  />
                  <path
                    d="M-70.1565 36.3873H-115L-42.4817 109.674L-115 181.424H-70.1565L-19.4193 133.249L-7.63193 143.499L-79.894 216.786H-35.0505L39.5178 143.499L-70.1565 36.3873Z"
                    fill="#459042"
                  />
                </g>
              </svg>
            </div>

          </div>
        </div>
      </section>

      <section class="mt-5 other-stories-section mb-sm-5">
        <h2 class="font-weight-bolder text-center mb-4">
          Other Stories
        </h2>
        <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
          <StoryCard v-for="(featuredStory, index) in featuredStories" :key="index" :story="featuredStory" />
        </div>
      </section>
    </b-overlay>
    <Footer />
  </div>
  </template>
  
<script>
import { get } from 'lodash';
import { format } from "date-fns";
import { BLink, BRow, BCol, BButton, BImg, BOverlay } from 'bootstrap-vue'

import Header from "@/pages/public/header.vue"; 
import MobileMenu from "@/pages/public/MobileMenu.vue";
import Footer from "@/pages/public/services/shared/Footer.vue";
import StoryCard from "@/pages/public/stories/components/StoryCard.vue";
import Requirements from "@/pages/public/services/shared/Requirements.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      MobileMenu,
      Header,
      Footer,
      StoryCard,
      Requirements,
      BImg,
      BRow,
      BCol,
      BLink,
      BButton,
      BOverlay
    },
    data() {
      return {
        loading: false,
        featuredStories: [],
        story: {}
      }
    },
    watch: {
      '$route.params.id': {
        handler() {
          this.fetchStoryDetails();
        },
      }
    },
    created(){
      this.fetchStoryDetails();
    },
    methods: {
      async fetchStoryDetails(){
        try {
          this.loading = true

          const { id } = this.$route.params;
          const response = await this.useJwt().sharedService.fetchStory(id);
          const { data: story_response, featured_stories } = get(response, "data");

          this.story = story_response;
          this.featuredStories = featured_stories.filter(story => story._id !== id);
        } catch (error){
          const error_message = get(error, "response.data.message") || error.message
          this.errorMessage = error_message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });

          this.story = undefined
        } finally {
          this.loading = false
        }
      },
      goToStoryDetails(id) {
        this.$router.push({ name: 'single-story', params: { id } })
      },
      getPublishedDate() {
        if (this.story && this.story.created) {
          const published = this.getValueFromSource(this.story, 'created', '');
          return format(new Date(published), 'EEEE, d, MMMM yyyy')
        }
        return ''
      }
    }
  }
</script>
  
<style lang="scss" scoped>
  @import '@core/scss/vue/pages/page-public.scss';

  .story-content img {
    max-width: 85%;
    max-height: 20%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    @media (max-width: 768px) {
      width: 80vw;
    }
  }

  .story-container {
    width: 85%;
    margin: auto;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 92%;
      margin: auto;
    }
  }

  .featured-image {
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
      width: 100%;
      margin: auto;
    }
  }

  .featured-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .story-title {
    @media (max-width: 768px) {
      font-size: 30px !important;
    }
  }
  </style>

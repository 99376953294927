<template>
  <div
    class="d-flex flex-column align-items-center w-35 w-sm-50 mr-2 mr-sm-0 mb-sm-4 mb-2 detail-card"
  >
    <div class="story-image-container">
      <img
        :src="getValueFromSource(story, 'featured_image.path', '')"
        class="story-image"
        alt="featured story image"
      />
    </div>
    <div class="px-2 text-center">
      <h3 class="font-weight-bolder">
        {{ story.title }}
      </h3>
      <p class="line-clamp">
        {{ story.excerpt }}
      </p>
    </div>
    <b-button class="continue-btn" variant="primary" block type="submit" size="lg" @click="goToStoryDetails(story._id)">
      Continue Reading
      <feather-icon icon="ArrowRightIcon" />
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  },
  props: {
    story: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    goToStoryDetails(id) {
      this.$router.push({ name: 'single-story', params: { id } })
    }
  }
}
</script>

'<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>'
